import { downloadDocument } from "../services/api/data/document";
import DocumentFile from "./Document";

export default class MapModel extends DocumentFile {
  static url = "maps/";

  download() {
    const params = new URLSearchParams();
    downloadDocument(`${MapModel.url}${this.id}/download/`, `${this.name}.jpeg`);
  }
}
