import { getFromApiFunction } from "../services/api/apiFunctions";
import BaseModel from "./BaseModel";

export class Actor extends BaseModel {
  email: string;
  phones: string[];
  static url: string = "stakeholders/";

  constructor(id: number, name: string, email: string, phones: string[]) {
    super(id, name);
    this.email = email;
    this.phones = phones;
  }

  static fromJson(json: any): Actor {
    return new this(json.id, json.name, json.email, json.phones);
  }

  static fetchCategories(params: URLSearchParams = new URLSearchParams()): any {
    return getFromApiFunction(this.url + "categories/", params);
  }
}
