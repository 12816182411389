import { useState, useEffect } from "react";
import SelectComponent from "./SelectComponent";
import { getDepartement } from "../../services/api/data/departement_services";
import Departement, { Commune } from "../../models/Departement_model";
import Pole from "../../models/Pole_model";
import { getPole } from "../../services/api/data/pole_services";
import { getVillages } from "../../services/api/data/village_services";
import Village from "../../models/Village_model copy";
import Thematic from "../../models/Thematic";
import { getFromApiFunction } from "../../services/api/apiFunctions";
import Composante, {
  Genders,
  Intervention,
  InterventionType,
} from "../../models/InterventionModel";

export default function IndicateurSuivi(props: any) {
  const {
    isOpen,
    onClose,
    actionSetMarkers,
    regionLayerSetter,
    setCommuneName,
    actionSetNetworkAxis,
  } = props;

  const [selectedInterventions, setSelectedInterventions] = useState<any>([]);

  const [years, setYears] = useState<any>([]);
  const [axisYears, setAxisYears] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState<any>();

  const [composantes, setComposantes] = useState<any>([]);
  const [selectedComposante, setSelectedComposante] = useState<any>();

  const [interventionTypes, setInterventionTypes] = useState<any>([]);
  const [selectedInterventionTypes, setSelectedInterventionTypes] = useState<any>([]);

  const [communes, setCommunes] = useState<Commune[]>([]);
  const [selectedCommune, setSelectedCommune] = useState<any>();

  const [departements, setDepartements] = useState<Departement[]>([]);
  const [selectedDepartement, setSelectedDepartement] = useState<any>();

  const [interventions, setInterventions] = useState<any>([]);
  const [fetchingData, setFetchingData] = useState<any>(false);

  const notNull = (value: any) => {
    return value !== null && value !== undefined;
  };

  // To change
  const Interventions = {
    axe: "Réseau électrique",
    luminaire: "Luminaires",
    transformer: "Transformers",
  };

  const notDefined = (value: any) => {
    return value === null || value === undefined;
  };

  useEffect(() => {
    Composante.fetchData().then((response: any) => {
      const results = Composante.fromJsonList(response.data.results);
      setComposantes(Composante.fromJsonList(response.data.results));
    });
  }, []);

  useEffect(() => {
    setInterventionTypes([]);
    setSelectedInterventionTypes([]);
    if (notDefined(selectedComposante)) return;

    const params = new URLSearchParams();

    if (selectedComposante) {
      params.append("composante", selectedComposante);
    }
    InterventionType.fetchData(params).then((response: any) => {
      setInterventionTypes(InterventionType.fromJsonList(response.data.results));
    });
  }, [selectedComposante]);

  useEffect(() => {
    setYears([]);
    setSelectedYear(null);
    if (!selectedInterventionTypes.length) return;
    // Join selectedInterventions with a comma
    const filterValue = selectedInterventionTypes.join(",");

    getFromApiFunction(
      Intervention.url + "years/",
      new URLSearchParams([["intervention_type", filterValue]]),
    ).then((response: any) => {
      setYears(response.data);
    });
  }, [selectedInterventionTypes]);

  useEffect(() => {
    setDepartements([]);
    setSelectedDepartement(null);
    if (notDefined(selectedYear)) return;

    let params = new URLSearchParams([
      ["intervention_types", selectedInterventionTypes.join(",")],
    ]);
    if (selectedYear) {
      params.append("year", selectedYear);
    }
    Departement.fetchData(params).then((response: any) => {
      setDepartements(Departement.fromJsonList(response.data));
    });
  }, [selectedYear]);

  useEffect(() => {
    setCommunes([]);
    setSelectedCommune(null);
    actionSetMarkers([], true);
    if (!selectedDepartement) return;

    let params = new URLSearchParams([
      ["intervention_types", selectedInterventionTypes.join(", ")],
    ]);
    if (selectedYear) {
      params.append("year", selectedYear);
    }
    if (selectedDepartement) {
      params.append("departement", selectedDepartement);
    }

    Commune.fetchData(params).then((response: any) => {
      setCommunes(Commune.fromJsonList(response.data));
    });
  }, [selectedDepartement]);

  const recursiveGetInterventions = (params: URLSearchParams) => {
    // Extract page params from url
    let next = "";
    Intervention.fetchData(params).then((response: any) => {
      setInterventions(Intervention.fromJsonList(response.data.results));
      next = response.data.next;
      if (!next) {
        setFetchingData(false);
        return;
      }
      const url = new URL(next);
      const offset = url.searchParams.get("offset");
      params.set("offset", offset ? offset : "");
      recursiveGetInterventions(params);
    });
  };

  useEffect(() => {
    actionSetMarkers(interventions);
  }, [interventions]);

  useEffect(() => {
    actionSetMarkers([], true);
    console.log(selectedCommune, notDefined(selectedCommune));
    if (
      notDefined(selectedYear) ||
      notDefined(selectedDepartement) ||
      notDefined(selectedCommune)
    )
      return;
    let params = new URLSearchParams([
      ["intervention_type", selectedInterventionTypes.join(",")],
    ]);
    if (selectedYear) {
      params.append("year", selectedYear);
    }
    if (selectedDepartement) {
      params.append("departement", selectedDepartement);
    }
    if (selectedCommune) {
      params.append("commune", selectedCommune);
    }
    setFetchingData(true);
    recursiveGetInterventions(params);
  }, [selectedCommune]);

  return (
    <div
      className={`overlay-inner absolute left-[40px] top-[150px] ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="tracking-inner absolute">
        <div
          className="lc-modal absolute left-0 top-0 w-fit sm:w-[400px] bg-black/50 p-5 z-[999] transition-pacofide"
          data-lc-target="tracking"
        >
          <button
            className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-white text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
            data-lc-target="#tracking"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: "currentColor" }}
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </button>
          <div className="space-y-12">
            <form className="relative text-white">
              <h3 className="flex items-center justify-center space-x-4 text-base dfont-medium">
                Suivi des interventions du projet
              </h3>
              <div className="space-y-6 mt-6 overflow-y-auto overflow-x-hidden max-h-[300px] md:max-h-none">
                <div className="sm:space-y-6 sm:block sm:w-[370px]">
                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[150px] text-sm">
                      Sutructuration
                    </span>

                    <SelectComponent
                      options={[
                        Composante.default(),
                        ...Composante.toSelectOption(composantes),
                      ]}
                      onChange={setSelectedComposante}
                      customPlaceHolder="Sélectionner une composante"
                      className="w-full"
                      value={selectedComposante}
                      disabled={fetchingData}
                    />
                  </label>
                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[150px] text-sm">
                      Interventions
                    </span>

                    <SelectComponent
                      options={InterventionType.toSelectOption(interventionTypes)}
                      onChange={setSelectedInterventionTypes}
                      customPlaceHolder="Sélectionner une intervention"
                      className="w-full"
                      value={selectedInterventionTypes}
                      multiple
                      disabled={fetchingData}
                    />
                  </label>
                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[150px] text-sm">
                      Année d'intervention
                    </span>

                    <SelectComponent
                      options={
                        // Merge axisYears and years unique values
                        [
                          {
                            label: "Tout",
                            value: 0,
                          },
                          ...Array.from(new Set([...axisYears, ...years])).map(
                            (year) => ({
                              label: year,
                              value: year,
                            }),
                          ),
                        ]
                      }
                      onChange={setSelectedYear}
                      customPlaceHolder="Année"
                      className="w-full"
                      value={selectedYear}
                      disabled={fetchingData}
                    />
                  </label>

                  {/* {region === "pole" && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">Pole</span>

                      <SelectComponent
                        options={Pole.toSelectOption(poles)}
                        onChange={(value) => changeVariable(value, 5)}
                        customPlaceHolder="Sélectionner un pole"
                        value={selectedPole}
                      />
                    </label>
                  )}*/}

                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[150px] text-sm">Departement</span>

                    <SelectComponent
                      options={[
                        Departement.default(),
                        ...Departement.toSelectOption(departements),
                      ]}
                      onChange={setSelectedDepartement}
                      customPlaceHolder="Sélectionner un département"
                      value={selectedDepartement}
                      disabled={fetchingData}
                    />
                  </label>

                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[150px] text-sm">Commune</span>

                    <SelectComponent
                      options={[Commune.default(), ...Commune.toSelectOption(communes)]}
                      onChange={setSelectedCommune}
                      customPlaceHolder="Sélectionner une commune"
                      value={selectedCommune}
                      disabled={fetchingData}
                    />
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
