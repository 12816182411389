import BaseModel from "./BaseModel";
import { Tooltip } from "antd";

export default class Composante extends BaseModel {
  static url = "/composantes/";

  code: string;

  constructor(id: number, name: string, code: string) {
    super(id, name);
    this.id = id;
    this.name = name;
    this.code = code;
  }

  static fromJson(json: any): BaseModel {
    return new this(json.id, json.name, json.code);
  }

  static toSelectOption(values: Composante[]): any {
    return values.map((value) => {
      return {
        value: value.id,
        label: (
          <Tooltip zIndex={999999999} title={value.name}>
            {value.code}
          </Tooltip>
        ),
      };
    });
  }
}

export class Intervention extends BaseModel {
  static url = "/interventions/";

  properties: object;
  intervention_type: InterventionType;
  full_location: string = "";
  geom: object = {};

  constructor(
    id: number,
    name: string,
    properties: object = {},
    intervention_type: object = {},
    localities: Array<any> = [],
    geom: object = {},
  ) {
    super(id, name);
    this.properties = properties;
    this.intervention_type = InterventionType.fromJson(intervention_type);
    this.geom = geom;

    if (!localities.length) return;

    let commune = localities[0].commune;
    this.full_location = `${commune} - ${localities
      .map((loc: any) => loc.name)
      .join(", ")}`;
  }

  static fromJson(json: any): BaseModel {
    return new this(
      json.id,
      json.name,
      json.properties,
      json.intervention_type,
      json.localities,
      json.geom,
    );
  }
  static toSelectOption(values: Intervention[]): any {
    return values.map((value) => {
      return {
        value: value.id,
        label: value.name,
      };
    });
  }
}

export enum Genders {
  WOMAN,
  BOTH,
}

export class InterventionType extends BaseModel {
  static url = "/intervention_types/";

  is_gender_related: boolean;
  slug: string;

  constructor(id: number, name: string, is_gender_related: boolean, slug: string) {
    super(id, name);
    this.is_gender_related = is_gender_related;
    this.slug = slug;
  }

  static fromJson(json: any): InterventionType {
    return new this(json.id, json.name, json.is_gender_related, json.slug);
  }
}
