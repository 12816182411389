/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  DoughnutController,
  LineController,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { transformToDataSet, barChatOption } from "../../../core/helpers";
import { getFromApiFunction } from "../../../services/api/apiFunctions";
import Loading from "../../../core/components/Loading";

// import lodash
import _ from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  DoughnutController,
  LineController,
  Title,
  Tooltip,
  Legend,
);

export const tempOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
    backgroundColor: "#ebab34",
  },
};

export default function BarChart(props: any) {
  const {
    graphName,
    baseEndpoint,
    statsUnitProps,
    paramsProps,
    color,
    customHeight,
    customWidth,
    dataFilterHelper,
    otherDataSet,
    stacked,
    withLegend,
    withoutRegionSelector,
  } = props;

  const [chartData, setChartData] =
    useState<ChartData<"bar", (number | [number, number] | null)[], unknown>>();
  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [statsUnit, setStatsUnit] = useState(statsUnitProps);
  const [params, setParams] = useState(paramsProps);
  const [updateCount, setUpdateCount] = useState(0);

  const firstRender = useRef(true); //To check if component is mounted

  const getChartData = async () => {
    setApiCalling(true);
    setHasError(false);
    if (
      statsUnit === "commune" &&
      !(params && (params.get("departement") || params.get("pole")))
    )
      return;

    if (
      statsUnit !== "commune" &&
      params &&
      (params.get("departement") || params.get("pole"))
    )
      return;

    const fullUrl =
      baseEndpoint + "/" + (withoutRegionSelector ? "" : `by_${statsUnit}/`);
    await getFromApiFunction(fullUrl, params)
      .then((response: any) => {
        let data = dataFilterHelper ? dataFilterHelper(response.data) : response.data;

        const chartData = transformToDataSet(graphName, data, color, otherDataSet);
        setChartData(chartData);
        setApiCalling(false);
      })
      .catch((error: any) => {
        setHasError(true);
        setApiCalling(false);
      });
  };

  useEffect(() => {
    setStatsUnit(statsUnitProps);
  }, [statsUnitProps]);

  useEffect(() => {
    if (
      _.isEqual(
        Object.fromEntries(paramsProps.entries()),
        Object.fromEntries(params.entries()),
      )
    )
      return;
    setParams(paramsProps);
  }, [paramsProps]);

  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //   } else {
  //
  //     if (params && params.keys().length > 0) {
  //
  //       setStatsUnit("commune");
  //     } else {
  //
  //     }
  //   }
  // }, [params]);

  const handleBarClick = (event: any, chartElements: any) => {
    // if (chartElements.length > 0) {
    //   const index = chartElements[0].index;
    //   const labels = chartData?.labels;
    //   if (labels) {
    //     const label: any = labels[index];
    //     buildParameter(label);
    //     const params = new URLSearchParams();
    //     params.append(statsUnit, label);
    //     setParams(params);
    //   }
    // }
  };

  useEffect(() => {
    getChartData();
  }, [statsUnit, params]);

  return (
    <>
      {(apiCalling || hasError) && <Loading />}
      {!apiCalling && !hasError && (
        <Bar
          options={barChatOption(graphName, handleBarClick, stacked, withLegend)}
          height={customHeight || 300}
          width={customWidth}
          data={
            chartData as ChartData<"bar", (number | [number, number] | null)[], unknown>
          }
          // className="w-fit"
        />
      )}
    </>
  );
}
