/* eslint-disable react/prop-types */
import React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, GeoJSON } from "react-leaflet";
import { useCustomEventListener } from "react-custom-events";
import country from "../../shapes/benin.json";
import { emitCustomEvent } from "react-custom-events";

// To be imported from backend
const layers = [];

export default function RegionSelector(props) {
  const { regions, filterMethod } = props;

  const commonStyle = {
    color: "black",
    weight: 1.3,
  };
  const interventionStyle = {
    ...commonStyle,
    fillColor: "#abf4c9",
    fillOpacity: 1,
  };
  const deselectedStyle = {
    ...commonStyle,
    fillColor: "gray",
    fillOpacity: 0.3,
  };
  const neutralStyle = {
    ...commonStyle,
    fillColor: "white",
    fillOpacity: 1,
  };

  const setInitialStyle = (layer) => {
    layer.setStyle(interventionStyle);
  };

  useCustomEventListener("re-init", () => {
    layers.forEach((layer) => {
      setInitialStyle(layer);
    });
  });

  const onEachFeature = (feature, layer) => {
    layer
      .bindTooltip(feature.properties.name, {
        permanent: true,
        direction: "center",
        className: "my-labels",
      })
      .openTooltip();
    layer.on({
      click: () => {
        layer.setStyle(interventionStyle);
        layers.forEach((element) => {
          if (element.feature.properties.name !== feature.properties.name) {
            element.setStyle(deselectedStyle);
          }
        });
        if (filterMethod) {
          filterMethod(feature.properties.name);
        }
        emitCustomEvent("filter", feature.properties.name);
      },
    });
    setInitialStyle(layer);
    layers.push(layer);
  };

  const bounds = L.geoJSON(country).getBounds();
  return (
    <MapContainer
      bounds={bounds}
      zoomControl={false}
      dragging={false}
      zoom={12}
      doubleClickZoom={false}
      scrollWheelZoom={false}
    >
      <GeoJSON data={regions} onEachFeature={onEachFeature} />
    </MapContainer>
  );
}
