import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Filler,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  BarController,
  CategoryScale,
} from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";
import { IconButton } from "@mui/material";
import { emitCustomEvent } from "react-custom-events";
import RegionSelector from "./RegionSelector";
import { useOutletContext } from "react-router-dom";
import ChartRenderer from "./ChartsRenderer";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import {
  TERRITORY_DIVISION_TYPES,
  ChartDataTypeInterface as DivisionTypeInterface,
} from "../../core/constants";
import { TabsProps, Tabs } from "antd";
import { getFromApiFunction } from "../../services/api/apiFunctions";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  ArcElement,
  BarController,
  Filler,
  Tooltip,
  Legend,
  // ChartDataLabels,
);

type ContextType = { filterParams: any; selectedDivTypeName: any };

// Array of ten different colors
const colors = [
  "rgba(255, 99, 132, 0.5)",
  "rgba(54, 162, 235, 0.5)",
  "rgba(255, 206, 86, 0.5)",
  "rgba(75, 192, 192, 0.5)",
  "rgba(153, 102, 255, 0.5)",
  "rgba(255, 159, 64, 0.5)",
  "rgba(255, 99, 132, 0.5)",
  "rgba(8, 120, 212, 0.5)",
  "rgba(211, 155, 14, 0.5)",
  "rgba(29, 73, 73, 0.5)",
];

function GraphPage(props: any) {
  /**
   * In the following base code:
   * - divisionType is a specific type of territory division (departments or poles)
   * - division is a specific division of a given type (Ex: Alibori for departments and PDA1 for poles)
   */

  const {
    indicatorCategory,
    baseEndpoint,
    otherDataSet,
    stacked,
    intervention,
    withoutRegionSelector,
    withLegend,
  } = props;
  const [selectedDivisionType, setSelectedDivisionType] = useState(
    TERRITORY_DIVISION_TYPES[0],
  );
  const [selectedDivTypeName, setDivisionTypeName] = useState(selectedDivisionType.id);
  const [filterParams, setFilterParams] = useState<URLSearchParams>(
    new URLSearchParams([["intervention", intervention?.id]]),
  );
  const [statsUnit, setStatsUnit] = useState(TERRITORY_DIVISION_TYPES[0].id);

  const changeDivisionType = (newDivisionType: DivisionTypeInterface) => {
    setSelectedDivisionType(newDivisionType);
    setDivisionTypeName(newDivisionType.id);
    setFilterParams(new URLSearchParams([["intervention", intervention?.id]]));
  };

  const reInit = () => {
    emitCustomEvent("re-init");
    setSelectedDivisionType(selectedDivisionType);
    setStatsUnit(selectedDivisionType.id);
    setFilterParams(new URLSearchParams([["intervention", intervention?.id]]));
  };

  const filterByDivision = (divisionName: string) => {
    const params = new URLSearchParams([
      [selectedDivisionType.id, divisionName],
      ["composante", intervention?.id],
    ]);
    setFilterParams(params);
    setStatsUnit("commune");
  };

  useEffect(() => {
    // setStatsUnit("commune");
  }, [filterParams]);

  useEffect(() => {
    if (intervention) {
      const filters = new URLSearchParams([["intervention", intervention.id]]);
      if (selectedDivisionType)
        filters.append(
          selectedDivisionType.id,
          filterParams.get(selectedDivisionType.id) || "",
        );
      setFilterParams(filters);
    }
  }, [intervention]);

  const buildParameter = (label: string) => {
    const params = new URLSearchParams();
    params.append(statsUnit, label);
    setFilterParams(params);
    return params;
  };

  let defaulRegionOptions = TERRITORY_DIVISION_TYPES.map((value) => {
    return {
      value: value.id,
      label: value.label,
    };
  });

  const changeRegion = (element: any) => {
    let newDivType: any = TERRITORY_DIVISION_TYPES.find(
      (elm: any) => elm.id === element,
    );
    if (newDivType) {
      changeDivisionType(newDivType);
    }
    setStatsUnit(element);
  };

  return (
    <>
      <div
        className="px-4 w-screen sm:flex justify-between"
        style={{ height: "calc(100vh - 129px)" }}
      >
        <div className="relative h-[70%] sm:h-full flex-grow max-w-[450px] min-w-[400px]">
          {withoutRegionSelector ? (
            <div
              className="h-full w-full hidden md:block"
              style={{
                background: 'no-repeat center url("assets/carte.jpeg")',
                backgroundSize: "contain",
              }}
            ></div>
          ) : (
            <>
              <RegionSelector
                key={TERRITORY_DIVISION_TYPES[0].id}
                filterMethod={filterByDivision}
                regions={TERRITORY_DIVISION_TYPES[0].geojson}
                interventionSet={TERRITORY_DIVISION_TYPES[0].interventionSet}
              />
              <div className="absolute top-1 z-40 ">
                <IconButton onClick={reInit}>
                  <ArrowPathIcon className="w-10" />
                </IconButton>
              </div>
            </>
          )}
        </div>
        <div className="p-4 h-full flex-grow">
          <ChartRenderer
            indicatorCategory={indicatorCategory}
            filterParams={filterParams}
            selectedDivTypeName={selectedDivTypeName}
            statsUnitProps={statsUnit}
            params={filterParams}
            buildParameter={buildParameter}
            intervention={intervention}
            baseEndpoint={baseEndpoint}
            otherDataSet={otherDataSet}
            stacked={stacked}
            withoutRegionSelector={withoutRegionSelector}
            withLegend={withLegend}
          />
        </div>
      </div>
    </>
  );
}

export default function Graph() {
  const [sectors, setSectors] = useState<any[]>([]);
  const [work_types, setWorkTypes] = useState<any[]>([]);
  const [intervention] = useOutletContext<any>();

  const [categories, setCategories] = useState<any>([]);
  const [localIntervention, setIntervention] = useState(intervention);

  useEffect(() => {
    let params = new URLSearchParams([["composante", intervention.id]]);
    getFromApiFunction("indicators/categories/", params).then((response) => {
      setCategories(response.data);
    });
  }, [localIntervention]);

  useEffect(() => {
    setIntervention(intervention);
  }, [intervention]);

  const items: TabsProps["items"] = [
    {
      key: "national",
      label: "National",
      children: (
        <GraphPage
          indicatorCategory="national"
          baseEndpoint="indicators_values/by_year"
          intervention={intervention}
          withoutRegionSelector
        />
      ),
    },
    {
      key: "communal",
      label: "Communal",
      children: (
        <GraphPage
          baseEndpoint="indicators_values"
          intervention={intervention}
          indicatorCategory="communal"
        />
      ),
    },
    {
      key: "sector",
      label: "Sectorielle",
      children: (
        <GraphPage
          indicatorCategory="sector"
          baseEndpoint={"indicators_values/by_sector"}
          otherDataSet={sectors.map((sector: any, index: number) => ({
            title: sector.name,
            key: sector.slug,
            color: colors[colors.length - (index % colors.length) - 1],
          }))}
          intervention={intervention}
          stacked
          withLegend
        />
      ),
    },
    {
      key: "work_type",
      label: "Type de travaux",
      children: (
        <GraphPage
          indicatorCategory="work_type"
          baseEndpoint={"indicators_values/by_work_type"}
          intervention={intervention}
          otherDataSet={work_types.map((type: any, index: number) => ({
            title: type.name,
            key: type.slug,
            color: colors[index % colors.length],
          }))}
          stacked
          withLegend
        />
      ),
    },
  ];

  useEffect(() => {
    getFromApiFunction("sectors/").then((response) => {
      setSectors(response.data.results);
    });
    getFromApiFunction("work_types/").then((response) => {
      setWorkTypes(response.data.results);
    });
  }, []);

  return (
    categories && (
      <Tabs
        className="pt-[67px]"
        defaultActiveKey="1"
        items={items.filter((item: any) => categories.includes(item.key))}
      />
    )
  );
}
