import CategoryActor from "./CategoryActor";
import { Box, Tab } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { useEffect, useState } from "react";
import { Actor } from "../../models/Actor_model";

export default function ActorPage() {
  const [value, setValue] = useState("0");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    Actor.fetchCategories().then((response: any) => {
      setCategories(response.data);
    });
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="pt-[67px] max-w-[1400px] mx-auto my-0 px-4 md:px-10 flex items-center justify-between">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {categories.map((category: any, index) => {
                return (
                  <Tab
                    label={category.cname}
                    key={category.cpk}
                    value={index.toString()}
                    className="!text-[11px] sm:!text-sm"
                  />
                );
              })}
            </TabList>
          </Box>
          <>
            {categories.map((category: any, index) => {
              return (
                <TabPanel value={index.toString()} className="!px-0 !py-6">
                  <CategoryActor category={category.cpk} />
                </TabPanel>
              );
            })}
          </>
        </TabContext>
      </Box>
    </div>
  );
}
