import { useState, useEffect } from "react";
import BarChart from "../graphs/graph_components/BarChart";
import { Button, Carousel } from "antd";
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getFromApiFunction } from "../../services/api/apiFunctions";

function CustomNextArrow(props: any) {
  const { className, style, onClick } = props;
  return <ArrowRightCircleIcon className={className} style={style} onClick={onClick} />;
}

function CustomPrevArrow(props: any) {
  const { className, style, onClick } = props;
  return <ArrowLeftCircleIcon className={className} style={style} onClick={onClick} />;
}

const settings = {
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
};

export default function Home() {
  const [intervention, setFiliere, publicIsAnalyzeOpenSetter] = useOutletContext<any>();
  const navigate = useNavigate();

  const [indicators, setIndicators] = useState([]);

  const moveToMapping = (filiereConst: any) => {
    setFiliere(filiereConst);
    navigate("/mapping");
  };

  useEffect(() => {
    getFromApiFunction("/indicators/?show_on_home=1").then((response) => {
      setIndicators(response.data.results);
    });
  }, []);
  return (
    <div className="home-background h-screen">
      <div className="max-w-[1400px] mx-auto my-0 h-full">
        <div className="pt-2 flex justify-center gap-2 items-center sm:flex-row flex-col">
          <span
            className="w-[200px] lg:w-auto !h-[67px] lg:!h-[120px]"
            style={{ overflow: "hidden" }}
          >
            <img
              className="h-[180px] lg:h-[300px]"
              src="./assets/logo-p2ae.png"
              style={{ marginTop: "-27%" }}
            />
          </span>
          <span className="max-w-[200px] w-[200px] !h-[67px] lg:!h-[120px] lg:w-auto bg-white wb-logo">
            <img src="./assets/world-bank.png" />
          </span>
        </div>
        <div className="flex pt-12 custom-header">
          <h2 className="w-ful mx-auto my-0 text-center font-light text-xl font-normal tracking-[2px] sm:tracking-[5px] text-slate-400 color-green !text-lg sm:!leading-10 sm:!text-[2rem] md:!leading-[2.75rem] md:!text-[2.5rem] custom-title">
            PLATEFORME DE SUIVI DES INTERVENTIONS <br /> ET DES BENEFICIAIRES DU
            <span className="font-semibold"> P2AE</span>
          </h2>
        </div>
        <div className="flex justify-center gap-4 mt-12">
          <Button
            // type="primary"
            className="block m-auto text-xl font-bold home-button"
            onClick={() => navigate("mapping")}
          >
            MAPPING
          </Button>
          <Button
            // type="primary"
            className="block m-auto text-xl font-bold home-button"
            onClick={() => publicIsAnalyzeOpenSetter(true)}
          >
            TABLEAU DE BORD
          </Button>
        </div>
        <div className="mt-12 bg-white rounded-t-lg block m-auto h-[500px]">
          <div className="">
            <Carousel arrows dots={false} infinite={false} {...settings}>
              {indicators.map((indicator: any) => (
                <div className="sm:space-y-12 mx-auto my-0 h-full">
                  <div className="flex items-center justify-center space-x-9 p-7 lg:p-0">
                    <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-0 lg:space-x-10">
                      <span className="w-[700px] hidden sm:block" key={indicator.id}>
                        <BarChart
                          graphName={indicator.name}
                          baseEndpoint="indicators_values"
                          statsUnitProps={"year"}
                          paramsProps={
                            new URLSearchParams([["indicator", indicator.id]])
                          }
                          color={"#ebab34"}
                          customHeight={450}
                          customWidth={700}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
