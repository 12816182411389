import { ActiveElement, Chart, ChartEvent } from "chart.js";

const yeartoSelectOption = (values: number[]): any => {
  return values.map((value) => {
    return {
      value: value,
      label: value,
    };
  });
};

const transformToDataSet = (
  graphTitle: string,
  data: any,
  color?: any,
  otherValuesKeys?: Array<any>,
) => {
  let labels = data.map((element: any) => {
    return element.label;
  });
  let values = data.map((element: any) => {
    return element.value;
  });

  const moreDataTypes: any = [];
  if (otherValuesKeys) {
    otherValuesKeys.forEach((item: any) => {
      moreDataTypes.push({
        label: item.title,
        data: data.map((element: any) => {
          return element[item.key];
        }),
        backgroundColor: item.color || "rgba(255, 99, 132, 0.5)",
      });
    });
  }

  return {
    labels: labels,
    // When having other datasets we just ignore the default one
    datasets: moreDataTypes.length
      ? moreDataTypes
      : [
          {
            label: graphTitle,
            data: values,
            backgroundColor: color || "rgba(255, 99, 132, 0.5)",
          },
        ],
  };
};

const barChatOption: any = (
  graphTitle: string,
  onBarClick: any,
  stacked = false,
  withLegend = false,
) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: withLegend ? "top" : "hidden",
      },
      title: {
        display: true,
        text: graphTitle,
      },
    },
    onClick: onBarClick,
    scale: { ticks: { precision: 0 }, x: { stacked }, y: { stacked } },
  };
};

const getSelectionValues = (value: any, options: any[], isMulti?: boolean) => {
  let result = options.find((elmt: any) => elmt.value === value);
  if (isMulti) {
    result = options.filter((elmt: any) => value.includes(elmt.value));
  }
  return result;
};

function arraysEqual<T>(array1: T[], array2: T[]): boolean {
  // Check if the arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Sort the arrays and compare them
  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  for (let i = 0; i < array1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
}

export {
  yeartoSelectOption,
  transformToDataSet,
  barChatOption,
  getSelectionValues,
  arraysEqual,
};
