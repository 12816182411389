import { downloadDocument } from "../services/api/data/document";
import BaseModel from "./BaseModel";

export default class DocumentFile extends BaseModel {
  static url: string = "documents/";
  filename: string;
  size: number;
  created: Date;
  imageUrl: string = "";

  constructor(
    id: number,
    name: string,
    filename: string,
    size: number,
    created: Date,
    imageUrl: string = "",
  ) {
    super(id, name);
    this.filename = filename;
    this.size = size;
    this.created = created;
    this.imageUrl = imageUrl;
  }

  static fromJson(json: any) {
    return new this(
      json.id,
      json.name,
      json.filename,
      json.size,
      json.created,
      json.image,
    );
  }

  download() {
    const params = new URLSearchParams();
    downloadDocument(`${DocumentFile.url}${this.id}/download/`, this.filename);
  }
}
