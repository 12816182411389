import { Outlet } from "react-router-dom";
import Navbar, { useLocalStorageState } from "../../core/components/Navbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiLogout } from "../../services/api/auth/login";

export default function Dashboard() {
  const [selectedIntervention, setSelectedIntervention] = useLocalStorageState(
    "intervention",
    1,
  );
  const [isAnalyzeOpen, setIsAnalyzeOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <div>
      <div className="transition-pacofide border-b-[3px] border-transparent hover:border-white group relative user-icon">
        <a className="flex items-center px-2 py-4 space-x-2 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="9" r="3" stroke="white" strokeWidth="1.5" />
            <circle cx="12" cy="12" r="10" stroke="white" strokeWidth="1.5" />
            <path
              d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>{" "}
          <div className="deconnexion-container">
            <ul
              className="deconnexion group-hover:pointer-events-auto group-hover:top-[59px] group-hover:sm:top-[67px] z-[9999999] group-hover:flex"
              style={{ marginTop: "-5px" }}
            >
              <svg
                fill="#000000"
                height="20px"
                width="20px"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 490.3 490.3"
              >
                <g>
                  <g>
                    <path
                      d="M0,121.05v248.2c0,34.2,27.9,62.1,62.1,62.1h200.6c34.2,0,62.1-27.9,62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3
			s-12.3,5.5-12.3,12.3v40.2c0,20.7-16.9,37.6-37.6,37.6H62.1c-20.7,0-37.6-16.9-37.6-37.6v-248.2c0-20.7,16.9-37.6,37.6-37.6h200.6
			c20.7,0,37.6,16.9,37.6,37.6v40.2c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1
			C27.9,58.95,0,86.75,0,121.05z"
                    />
                    <path
                      d="M385.4,337.65c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l83.9-83.9c4.8-4.8,4.8-12.5,0-17.3l-83.9-83.9
			c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l63,63H218.6c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h229.8l-63,63
			C380.6,325.15,380.6,332.95,385.4,337.65z"
                    />
                  </g>
                </g>
              </svg>
              <button
                onClick={() => {
                  apiLogout();
                  navigate("/login");
                }}
              >
                Déconnexion
              </button>
            </ul>
          </div>
        </a>
      </div>
      <Navbar
        setSelectedIntervention={setSelectedIntervention}
        publicIsAnalyzeOpen={isAnalyzeOpen}
        publicIsAnalyzeOpenSetter={setIsAnalyzeOpen}
      />
      <div className="bg-white-500">
        <div className="bg-white-500">
          <Outlet
            context={[selectedIntervention, setIsAnalyzeOpen, setIsAnalyzeOpen]}
          />
        </div>
      </div>
    </div>
  );
}
