/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Polyline,
  LayersControl,
  FeatureGroup,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import country from "../../shapes/benin_commune.json";
import { MAP_VIEWS, LAYER_CONTROLS, Layers, weightStyle } from "../../core/constants";
import { getLayers, retrieveLayer } from "../../services/api/data/layer_services";
import { useDebounce } from "use-debounce";
import { PopupComponent } from "./MappingMarker";
import { PopupAreaComponent } from "./PopupAreaComponent";
import IndicateurSuivi from "../../core/components/IndicateurSuivi";
import IndicateurSuiviButton from "../../core/components/IndicateurSuiviButton";
import IndicateurSupperpositionButton from "../../core/components/IndicateurSupperpositionButton";
import ModalSupperposition from "../../core/components/ModalSupperposition";
import Departement, { Commune } from "../../models/Departement_model";
import Pole from "../../models/Pole_model";
import ReactLeafletCluster from "react-leaflet-cluster";

/* Note: DO NOT DELETE THIS COMMENT
When using local copy of leaflet css markers are not displayed properly
Following code is meant to solve the issue.
See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
*/
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const DEFAULT_MARKER_PER_CONTROL_LAYER = [
  {
    layerControlId: 1,
    markers: [],
  },
  {
    layerControlId: 2,
    markers: [],
  },
];

function MappingBased(props) {
  const { filiere } = props;

  const [viewIndex, setCurrentViewIndex] = useState(0);
  const bounds = L.geoJSON(country).getBounds();
  const [view, setView] = useState(MAP_VIEWS[viewIndex]);
  const [tileKey, setTileKey] = useState(MAP_VIEWS[viewIndex].id);
  const [layers, setLayers] = useState(Layers);
  const [projects, setProjects] = useState([]);
  const [params, setParams] = useState({});
  const [selectedProject, setSelectedProject] = useState([]);
  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);
  // const [markerPerControlLayer, setMarkerPerControlLayer] = useState(null);
  const [map, setMap] = useState();
  const [inputValue, setInputValue] = React.useState("");
  const [debouncedInputValue] = useDebounce(inputValue, 500);
  const [currentLayer, setCurrentLayer] = useState();

  const [isIndicOpen, setIsIndicOpen] = useState(true);
  const [isSupOpen, setIsSupOpen] = useState(true);
  const [markerPerControlLayer, setMarkerPerControlLayer] = useState();

  const [networkAxis, setNetworkAxis] = useState();

  // Layer that will be displayed on top of map (departements or poles)
  // Will depend of user choice on region select in IndicateurSuivi component
  const [regionLayer, setRegionLayer] = useState();

  const [communeName, setCommuneName] = useState(null);

  const [poles, setPoles] = useState([]);
  const [departements, setDepartements] = useState([]);
  const [communes, setCommunes] = useState([]);

  useEffect(() => {
    Pole.fetchData(params).then((response) => {
      setPoles(Pole.fromJsonList(response.data.results));
    });
  }, []);

  const closeIndic = () => {
    setIsIndicOpen(false);
  };
  const openIndic = () => {
    setIsIndicOpen(true);
  };

  const closeSup = () => {
    setIsSupOpen(false);
  };
  const openSup = () => {
    setIsSupOpen(true);
  };

  const updateMarkerPerControlLayer = (markers, reset = false) => {
    let existingMarkers = markerPerControlLayer ? markerPerControlLayer[0].markers : [];
    if (reset) {
      existingMarkers = [];
    }
    let newMarkerPerControlLayer = {
      layerControlId: 1,
      markers: [...existingMarkers, ...markers],
    };

    setMarkerPerControlLayer([newMarkerPerControlLayer]);
  };

  const updateNetworkAxis = function (axis) {
    setNetworkAxis(axis);
  };

  const changeMapSupperposition = (newIndex) => {
    setCurrentViewIndex(newIndex);
  };

  useEffect(() => {}, [markerPerControlLayer]);

  const changeProject = (event) => {
    setSelectedProject(event);
  };

  const onCarteNameChanged = (e) => {
    setInputValue(e);
    const params = new URLSearchParams();
    params.append("project", selectedProject);
    params.append("name", inputValue);
    requestTogetLayers(params);
  };

  useEffect(() => {
    setView(MAP_VIEWS[viewIndex]);
    setTileKey(MAP_VIEWS[viewIndex].id);
  }, [viewIndex]);

  const requestTogetLayers = async (params) => {
    await getLayers(params).then((response) => {
      const data = response.data.results;
      const layersToSet = data.map((layer) => {
        const { id, name, image, description, geojson } = layer;
        return {
          id,
          name,
          image,
          description,
          geojson,
        };
      });
      setLayers(layersToSet);
    });
  };

  const getUniqueLayer = (params) => {
    retrieveLayer(params).then((response) => {
      const layerToSet = response.data;
      setCurrentLayer(layerToSet);
    });
  };

  const updateLayer = (layer) => {
    getUniqueLayer(layer.id);
  };

  const shapes = {
    name: "Communes",
    layer: communes,
    style: weightStyle,
    api: "communes/",
    selectValue: "commune",
  };

  return (
    <>
      <div
        className={`overlay-inner absolute left-[40px] top-[150px] z-500 ${
          isIndicOpen ? "hidden" : ""
        }`}
      >
        <IndicateurSuiviButton onClick={openIndic} />
      </div>
      <IndicateurSuivi
        isOpen={isIndicOpen}
        onClose={closeIndic}
        actionSetMarkers={updateMarkerPerControlLayer}
        regionLayerSetter={setRegionLayer}
        setCommuneName={setCommuneName}
        actionSetNetworkAxis={updateNetworkAxis}
      />
      <div
        className={`overlay-inner absolute right-[40px] top-[150px] z-500 ${
          isSupOpen ? "hidden" : ""
        }`}
      >
        <IndicateurSupperpositionButton onClick={openSup} />
      </div>
      <ModalSupperposition
        isOpen={isSupOpen}
        onClose={closeSup}
        action={changeMapSupperposition}
        currentIndex={viewIndex}
      />
      <div className="h-screen w-screen relative" style={{ zIndex: 1 }}>
        <div
          className="grow h-full w-full pt-[67px]"
          style={{
            display: "grid",
          }}
        >
          <MapContainer
            bounds={bounds}
            zoomControl={false}
            className="markercluster-map"
          >
            <TileLayer key={tileKey} {...view.tileLayerInfos} />
            {Layers.map((layer) => {
              if (regionLayer === layer.selectValue)
                return (
                  <FeatureGroup>
                    {layer.layer.features.map((feature, index) => {
                      const apiList =
                        regionLayer === "departement" ? departements : poles;
                      let dataJson = feature;
                      return (
                        <FeatureGroup key={`${dataJson.properties.name}-${index}`}>
                          <GeoJSON
                            data={dataJson}
                            style={{ color: ["green", "yellow"][viewIndex] }}
                          />
                          <PopupAreaComponent
                            dataJson={apiList.find(
                              (item) =>
                                item.name.toLowerCase() ===
                                feature.properties.name.toLowerCase(),
                            )}
                            downloadLink={`${
                              layer.api
                            }/${dataJson.properties.name.toUpperCase()}/report/`}
                            fileName={`${layer.selectValue.toUpperCase()}-${dataJson.properties.name.toUpperCase()}.pdf`}
                          />
                        </FeatureGroup>
                      );
                    })}
                  </FeatureGroup>
                );
            })}
            {communeName && (
              <FeatureGroup>
                {country.features.map((feature, index) => {
                  let dataJson = feature;
                  if (
                    dataJson.properties.name.toLowerCase() === communeName.toLowerCase()
                  ) {
                    return (
                      <FeatureGroup key={`${dataJson.properties.name}-${index}`}>
                        {
                          <GeoJSON
                            data={dataJson}
                            style={{ color: ["yellow", "green"][viewIndex] }}
                          />
                        }
                        <PopupAreaComponent
                          dataJson={communes.find(
                            (item) =>
                              item.slug.toLowerCase() === communeName.toLowerCase(),
                          )}
                          downloadLink={`communes/${communeName.toUpperCase()}/report/`}
                          fileName={`Commune-${communeName.toUpperCase()}.pdf`}
                        />
                      </FeatureGroup>
                    );
                  }
                })}
              </FeatureGroup>
            )}
            {markerPerControlLayer &&
              LAYER_CONTROLS.map((layerControl, index) => {
                let markers = [];
                const layerControlKey = `${layerControl.label}-${layerControl.id}`;
                const correspondingMarkers = markerPerControlLayer.find(
                  (element) => element.layerControlId === layerControl.id,
                );
                if (correspondingMarkers) {
                  markers = correspondingMarkers.markers;
                }
                return (
                  // <FeatureGroup key={index}>
                  <ReactLeafletCluster>
                    {markers.map((marker, index) => {
                      const markerkey = `${layerControl.label}-${index}`;
                      return marker.geom.type === "Point" ? (
                        <Marker position={marker.geom.coordinates} key={markerkey}>
                          <PopupComponent marker={marker} layerControl={layerControl} />
                        </Marker>
                      ) : (
                        <Polyline
                          key={markerkey}
                          positions={marker.geom.coordinates}
                          color="red"
                        >
                          <PopupComponent marker={marker} layerControl={layerControl} />
                        </Polyline>
                      );
                    })}
                  </ReactLeafletCluster>
                  // </FeatureGroup>
                );
              })}
            {networkAxis && (
              <FeatureGroup>
                {networkAxis.map((axis, index) => {
                  return (
                    <Polyline
                      key={index}
                      positions={axis.linestring.coordinates}
                      color="red"
                    >
                      <Popup>{axis.name}</Popup>
                    </Polyline>
                  );
                })}
              </FeatureGroup>
            )}
            {currentLayer && currentLayer.geojson && (
              <GeoJSON key={currentLayer.id} data={currentLayer.geojson} />
            )}
          </MapContainer>
        </div>
      </div>
    </>
  );
}

export default MappingBased;
