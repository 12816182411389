import { Intervention } from "./InterventionModel";

enum InterventionTypeSlug {
  LUMINAIRE = "luminaire",
  TRANSFORMER = "transformer",
  ELECTRIC_METER = "compteur",
}

export const SPECIFIC_PROPERTIES = {
  [InterventionTypeSlug.ELECTRIC_METER]: {
    recipient_type: "Type de bénéficiaire",
    neighborhood: "Village",
    owner_name: "Bénéficiaire",
    number: "Numéro compteur",
  },
  [InterventionTypeSlug.LUMINAIRE]: {
    axis: "Axe",
    status: "Etat Coffret",
    action_taken: "Mesure apportée",
  },
};
