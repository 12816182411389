import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Filler,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  BarController,
  CategoryScale,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
} from "chart.js";
import BarChart from "./graph_components/BarChart";
import { getFromApiFunction } from "../../services/api/apiFunctions";
// import BarChart from "./BarChart";
// import {
//   CATEGORIES_CHART,
//   CVA_CHARTS,
//   MALNUTRITION_CHART,
//   STAKE_HOLDER_CHART,
//   departementsIntervention,
// } from "../../constants";

ChartJS.register(
  LinearScale,
  RadialLinearScale,
  CategoryScale,
  BarElement,
  ArcElement,
  PointElement,
  BarController,
  LineElement,
  RadarController,
  PolarAreaController,
  Filler,
  Tooltip,
  Legend,
);

const scrollBarClasses =
  // "scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-slate-50 overflow-auto";
  "scrollbar scrollbar-thumb-w-[2px] scrollbar-thumb-rounded-full scrollbar-thumb-gray-400 scrollbar-track-gray-100 flex-grow sm:overflow-auto";

export default function ChartRenderer(props: any) {
  /**
   * In the following base code:
   * - divisionType is a specific type of territory division (departments or poles)
   * - division is a specific division of a given type (Ex: Alibori for departments and PDA1 for poles)
   */

  const {
    params,
    statsUnitProps,
    buildParameter,
    intervention,
    baseEndpoint,
    otherDataSet,
    stacked,
    indicatorCategory,
    withoutRegionSelector,
    withLegend,
  } = props;

  const [value, setValue] = React.useState(0);

  const [indics, setIndics]: any = React.useState([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [statsUnit, setStatsUnit] = useState(statsUnitProps);

  useEffect(() => {
    let params = new URLSearchParams([
      ["composante", intervention.id],
      ["category", indicatorCategory],
    ]);
    getFromApiFunction("indicators/", params).then((response) => {
      setIndics(response.data.results);
    });
  }, [intervention]);

  useEffect(() => {
    setStatsUnit(statsUnitProps);
  }, [statsUnitProps]);

  useEffect(() => {}, [statsUnit]);

  const width = "relative w-[250px] sm:w-fit mb-8 m-auto 2xl:mx-[10px]";

  return (
    <div className={`${scrollBarClasses} h-full align-center`}>
      <h1 className="text-center font-bold"> TABLEAU DE BORD DE SUIVI </h1>
      <h1 className="text-center font-bold mb-8 sm:w-1/2 m-auto">
        {intervention && intervention.name}
      </h1>
      <div className="gap-4 w-fit m-auto rounded-md flex">
        <div className={width}>
          {indics &&
            indics.map((indic: any, index: any) => {
              let filterParams = new URLSearchParams(params);
              filterParams.delete("composante");
              if (!filterParams.get("indicator")) {
                filterParams.append("indicator", indic.id);
              }
              return (
                <BarChart
                  graphName={indic.name}
                  baseEndpoint={baseEndpoint}
                  statsUnitProps={statsUnit}
                  paramsProps={filterParams}
                  buildParameter={buildParameter}
                  // Get a random color for each indic
                  color={"#" + Math.floor(Math.random() * 16777215).toString(16)}
                  key={index}
                  otherDataSet={otherDataSet}
                  stacked={indic.display_stacked && stacked}
                  withLegend={withLegend}
                  withoutRegionSelector={withoutRegionSelector}
                  customHeight={450}
                  customWidth={700}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}
