import axios from "axios";
import { emitCustomEvent } from "react-custom-events";

export const API_URL = process.env.REACT_APP_API_URL;

function getAxiosConfig() {
  // eslint-disable-next-line prefer-const
  let instanceConfig = {
    baseURL: API_URL,
    timeout: 5 * 60000,
    headers: {},
  };

  const axiosInstance = axios.create(instanceConfig);

  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("sig-pacofide-access");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const trackDownloadProgress = (event) => {
      const [base, pk, suffix] = config.url.split("/");
      const key = `${base}-${pk}-downloaded`;
      if (event.progress) {
        emitCustomEvent(key, Math.round(event.progress * 100));
      }
    };

    // Axios interceptor to monitor download progress
    config.onDownloadProgress = trackDownloadProgress;
    return config;
  });

  axiosInstance.interceptors.response.use(
    (config) => {
      // Intercept get `/token` response and globally set token in axios header.
      if (config?.data?.access && config?.data?.refresh) {
        localStorage.setItem("sig-pacofide-access", config.data.access);
        localStorage.setItem("sig-pacofide-refresh", config.data.refresh);
      }

      return config;
    },
    async (error) => {
      // "ERR_BAD_REQUEST"
      const originalConfig = error.config;
      // Intercept for token expiration error, and globally override token in
      // axios header.
      // On token expiration
      if (error?.response?.status !== 401) {
        return Promise.reject(error);
      }
      if (error?.response?.data?.code === "token_not_valid") {
        // If it's access token expiration
        if (
          error?.response?.data?.messages?.length &&
          error.response.data.messages[0].token_class === "AccessToken"
        ) {
          const refreshToken = localStorage.getItem("sig-pacofide-refresh");
          const payload = { refresh: refreshToken };
          try {
            const response = await axiosInstance.post("/token/refresh/", payload);
            const newToken = response.data.access;
            localStorage.setItem("sig-pacofide-access", newToken);
            originalConfig.headers.Authorization = `Bearer ${newToken}`;
            return axios(originalConfig);
            // eslint-disable-next-line no-shadow
          } catch (error) {
            // Logout
            localStorage.clear();
            // Redirect to login page
            window.location.href = "/login";
          }
          // eslint-disable-next-line brace-style
        }
        // Refresh token has expired
        else {
          // window.location.href = "/login";
        }
      } else if (!window.location.href.includes("login")) {
        window.location.href = "/login";
      }
    },
  );

  return axiosInstance;
}

export default getAxiosConfig;
